<template>
  <v-autocomplete
    v-model="values"
    :disabled="isUpdating"
    :items="items"
    :label="text"
    chips
    color="blue-grey lighten-2"
    item-text="name"
    item-value="value"
    return-object
    clearable
    multiple
    :search-input.sync="searchInput"
    @change="searchInput=''"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        class="white--text"
        color="blue-grey darken-2"
        close
        label
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:default="{ active, }">
      <v-list-item>
        <template v-slot:item="data">
          <v-list-item-action>
            <v-checkbox
              :input-value="active"
              color="primary"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content v-text="data.item.name"></v-list-item-content>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  data () {
    return {
      autoUpdate: true,
      values: this.selectedValues,
      isUpdating: false,
      searchInput: '',
    }
  },
  props: {
    items: Array,
    text: String,
    selectedValues: Array,
  },
  watch: {
    isUpdating (val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000)
      }
    },
    values () {
      this.$emit('data-selected', this.values)
    },
  },
  methods: {
    remove (item) {
      const index = this.values.map(x => typeof(x) == 'object' ? x.value : x).indexOf(item.value)
      if (index >= 0) this.values.splice(index, 1)
    },
  },
}
</script>