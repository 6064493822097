<template>
  <v-row class="px-10 pt-8 blue-grey lighten-5">
    <v-col>
      <v-row class="px-10">
        <v-col class="pb-0" cols="12" md="9">
          <SelectBookingType :selectedValue="selectedValue" :data="bookingTypes" :text="selectText" @selected-type="selectedType" />
        </v-col>
        <v-col class="pb-0" cols="12" md="3">
          <DatePicker @date-selected="selectedDate" :dateSelected="dateDatePicker" :text="datePickerText" :first_day_of_week="first_day_of_week"/>
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col class="pt-0" cols="12" md="9">
          <MultiSelect :items="activities" :text="multiselectText" @data-selected="multiselectValue" :selectedValues="activitiesSelected" />
        </v-col>
        <v-col class="pb-4" cols="12" md="3">
          <FormButton :search="checkAvailability" :text="submitText" :loading="loadingProp" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from './selects/DatePicker.vue'
import MultiSelect from './selects/Multiselect'
import SelectBookingType from './selects/Select'
import FormButton from './buttons/FormButton'
export default {
  components: {
    DatePicker,
    MultiSelect,
    SelectBookingType,
    FormButton,
  },
  data () {
    return {
      valuesSelected: [],
      dateSelected: '',
      typeSelected: '',
      submitText: this.$t('filter.check_availability'),
      selectText: this.$t('filter.booking_type'),
      multiselectText: this.$t('filter.activity'),
      datePickerText: this.$t('filter.date'),
    }
  },
  props: {
    bookingTypes: Array,
    activities: Array,
    selectedValue: Object,
    dateDatePicker: String,
    first_day_of_week: Number,
    activitiesSelected: Array,
    loadingProp: Boolean,
  },
  methods: {
    multiselectValue ( params ) {
      this.valuesSelected = params
      this.$emit('selected-data', this.valuesSelected)
    },
    selectedDate (params) {
      this.dateSelected = params
      this.$emit('selected-date-picker', this.dateSelected)
    },
    selectedType (params) {
      this.typeSelected = params
      this.$emit('type-selected', this.typeSelected)
    },
    checkAvailability () {
      this.$emit('check-availability')
    },
  },
}
</script>
