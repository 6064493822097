<template>
    <v-row>
        <v-col>
            <v-sheet height="auto">
                <v-calendar ref="calendar" type="category" :interval-minutes="intervalMinutes"
                    :event-text-color="eventTextColor" :interval-count="intervalCount" :events="categoriesEvents"
                    :categories="categoriesTypes" :event-color="getEventColor" @click:event="redirection" :colors="colors"
                    @mouseup:slot="redirection" :value="valueDateCalendar">
                    <template v-slot:event="{ event }">
                       <v-container bg fill-height grid-list-md text-center>
                            <v-layout wrap align-center>
                            <v-flex justify-center>
                                <div class="d-flex justify-space-between">
                                    <v-badge v-if="event.full" inline :color="event.eventcolor" :content="'FULL'"></v-badge>
                                    <v-icon class="material-icons" size="x-large" color="white">task_alt</v-icon>
                                    <span >{{event.name}}</span>
                                </div>
                            </v-flex>
                            </v-layout>
                    </v-container>
                    </template>
                </v-calendar>
            </v-sheet>
        </v-col>
    </v-row>
</template>
<script>
export default {
  props: {
    categoriesTypes: Array,
    categoriesEvents: Array,
    intervalCount: Number,
    intervalMinutes: Number,
    colors: Array,
    type: String,
    valueDateCalendar: String,
    eventTextColor: Function,
    redirection: Function,
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods: {
    getEventColor (event) {
      return event.color
    },
  },
}
</script>
<style lang="scss">
.v-calendar-category .v-calendar-daily_head-weekday {
    display: none;
}

.v-calendar .v-calendar-daily_head-day-label {
    display: none;
}

.v-calendar .v-event {
    display: none;
}

.theme--light.v-calendar-daily {
    border-left: none;
    border-top: none;
}

.v-calendar-daily__intervals-head::after {
    border: none;
}

.v-calendar-daily__interval {
    border: none;
}

.theme--light.v-calendar-daily .v-calendar-daily_head-day {
    border: none;
}

.v-calendar-daily__intervals-head {
    border: none;
    border-bottom: none;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
    border: none;
}

.theme--light.v-calendar-category .v-calendar-category__column,
.theme--light.v-calendar-category .v-calendar-category__column-header {
    border: none;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-head {
    border-right: none;
}

.theme--light.v-calendar-category .v-calendar-category__column {
    border: none;
}

.theme--light.v-calendar-daily .v-calendar-daily__intervals-head::after {
    bottom: 0%;
    height: 0%;
}

.theme--light.v-calendar-daily .v-calendar-daily__day {
    border-right: none;
    border-bottom: none;
}
.v-badge--inline .v-badge__wrapper {
    margin: 0 0px;
}
</style>