<template>
  <v-select
  v-model="defaultValue"
  :label="text"
  :items="data"
  item-text="name"
  item-value="value"
  required
  ></v-select>
</template>

<script>
export default {
  data () {
    return {
      defaultValue: this.selectedValue,
      inVenueLabel: this.$t('type.in_venue'),
      roomServiceLabel: this.$t('type.room_service'),
    }
  },
  props: {
    data: Array,
    selectedValue: Object,
    text: String,
  },
  watch: {
    defaultValue () {
      if (this.defaultValue == 'IN_VENUE') {
        this.$emit('selected-type', {
          name: this.inVenueLabel,
          value: this.defaultValue,
        })
      } else {
        this.$emit('selected-type', {
          name: this.roomServiceLabel,
          value: this.defaultValue,
        })
      }
    },
  },
}
</script>