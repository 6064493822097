<template>
  <v-row>
    <v-col>
      <v-sheet>
        <CalendarBase :categoriesTypes="categoriesType"
        :categoriesEvents="events"
        :colors="colors"
        :intervalCount="intervalCount"
        :intervalMinutes="intervalMin"
        :valueDateCalendar="valueDateCalendar"
        :eventTextColor="eventTextColor"
        :redirection="redirection"/>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import CalendarBase from '../calendar/CalendarBase.vue'
export default {
  components: {
    CalendarBase,
  },
  props: {
    categoriesType: Array,
    events: Array,
    colors: Array,
    intervalCount: Number,
    intervalMin: Number,
    valueDateCalendar: String,
    eventTextColor: Function,
    redirection: Function,
  },
}
</script>